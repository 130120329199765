import React from 'react';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
const name = 'madcolor';

let isMad = true;

const baseClass = 'rich-text__button';

const isMarkActive = (editor, format) => {
  const leaves = Editor.marks(editor);
  return leaves ? leaves[format] : false;
};

const toggleLeaf = (editor, format, isMad) => {
  const isActive = isMarkActive(editor, format);
  console.log(isActive);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    console.log('adding isMad');
    Editor.addMark(editor, format, isMad);
  }
};

// const ColorInput = ({ color }) => {
//   return <input onChange={onChange} type="color"></input>;
// };

// const onChange = (event) => {
//   color = event.target.value;
//   console.log(color);
// };

// const input = <ColorInput color={color}></ColorInput>;

const LeafButton = ({ format, children }) => {
  const editor = useSlate();
  const active = isMarkActive(editor, format);
  if (active) {
    const leafValue = Editor.marks(editor)[format];
  }

  return (
    <button
      type="button"
      className={[
        baseClass,
        isMarkActive(editor, format) && `${baseClass}__button--active`,
      ]
        .filter(Boolean)
        .join(' ')}
      style={{ color: '#e13737', fontWeight: 'bold' }}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleLeaf(editor, format, isMad);
      }}
    >
      {active ? 'Remove MAD' : 'Add MAD'}
    </button>
  );
};

const Button = ({ format, children }) => {
  // const editor = useSlate();
  // const active = isMarkActive(editor, format);

  return (
    <div>
      {/* {input} */}
      <LeafButton format={name}>{{ children }}</LeafButton>
    </div>
  );
};

const Leaf = ({ attributes, leaf, children }) => {
  if (leaf[name]) {
    console.log(leaf[name]);
    return (
      <span
        style={{
          color: '#e13737',
        }}
        {...attributes}
      >
        {children}
      </span>
    );
  }
  return <span {...attributes}>{children}</span>;
};

export default {
  name,
  Button,
  Leaf,
};
