import React from 'react';
import './Logo.scss';

export const customLogo = () => {
  return (
    <div className="logo">
      <img src="../assets/MAD.svg" alt="MAD Media Logo" />
    </div>
  );
};
