import { CollectionConfig } from 'payload/types';

const PostMedia: CollectionConfig = {
  slug: 'post-media',
  labels: {
    singular: 'Post Media',
    plural: 'Post Media',
  },
  admin: { group: 'Media' },
  access: {
    read: () => true,
  },

  fields: [
    {
      name: 'alt',
      label: 'Alt text - (describe the image to a blind person)',
      type: 'text',
      required: true,
    },
  ],
  upload: {
    staticURL: '/post-media',
    staticDir: 'media/post-media',
    formatOptions: {
      format: 'webp',
    },
    imageSizes: [
      {
        name: 'full',
        width: 1200,
        height: 600,
        position: 'centre',
      },
      // {
      //   name: 'card',
      //   width: 768,
      //   height: 1024,
      //   position: 'centre',
      // },
      // {
      //   name: 'tablet',
      //   width: 1024,
      //   // By specifying `null` or leaving a height undefined,
      //   // the image will be sized to a certain width,
      //   // but it will retain its original aspect ratio
      //   // and calculate a height automatically.
      //   height: null,
      //   position: 'centre',
      // },
    ],
    adminThumbnail: 'thumbnail',
    mimeTypes: ['image/*'],
  },
};

export default PostMedia;
