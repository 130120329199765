import { CollectionConfig } from 'payload/types';
import slugify from 'slugify';

const Sites: CollectionConfig = {
  slug: 'sites',
  access: {
    read: () => true,
  },
  // versioning with drafts enabled tells Payload to save documents to a separate collection in the database and allow publishing
  // versions: {
  //   drafts: true,
  // },
  admin: {
    useAsTitle: 'site',
    group: 'Site Content',
    pagination: { limits: [100] },
    disableDuplicate: true,
  },
  labels: {
    singular: 'Site',
    plural: 'Sites',
  },

  fields: [
    {
      name: 'slug',
      label: 'Site Slug',
      type: 'text',
      admin: {
        position: 'sidebar',
      },
      hooks: {
        beforeValidate: [
          ({ req: { payload }, data }) => {
            if (payload) {
              return slugify(data.site, { lower: true });
            }
          },
        ],
      },
    },
    {
      name: 'order',
      label: 'Order',
      type: 'number',
      admin: {
        position: 'sidebar',
      },
    },

    {
      name: 'site',
      label: 'Site Name',
      type: 'text',
      required: true,
    },
    {
      name: 'location',
      type: 'relationship',
      relationTo: 'locations',
      required: true,
      admin: {
        allowCreate: false,
      },
      // limit the options using the below query which uses the "archive" field set in the categories collection
      // filterOptions: {
      //   archived: { equals: false },
      // },
      // allow selection of one or more categories
      hasMany: false,
      // required:
    },

    {
      name: 'locationDescription',
      label: 'Location description',
      type: 'text',
      required: true,
    },
    {
      name: 'shortDescription',
      label: 'Short description',
      type: 'text',
      required: true,
    },
    {
      name: 'siteNote',
      label: 'Notable information',
      type: 'text',
      required: false,
    },
    {
      name: 'format',
      label: 'Format',
      type: 'select',
      required: true,
      options: [
        {
          label: 'Static',
          value: 'static',
        },
        {
          label: 'Digital',
          value: 'digital',
        },
        {
          label: 'On Wheels',
          value: 'mobile',
        },
      ],
    },

    // additional meta fields (coordinates, address, and google map link)
    {
      name: 'locationMeta',
      label: 'Location Meta',
      type: 'group',
      fields: [
        {
          name: 'coordinates',
          label: 'Coordinates',
          type: 'text',
        },
        {
          name: 'address',
          label: 'Address',
          type: 'text',
        },
        {
          name: 'googleMapLink',
          label: 'Google Map Link',
          type: 'text',
        },
      ],
    },
    // static meta
    {
      name: 'staticMeta',
      label: 'Static Options',
      type: 'group',
      admin: {
        condition: (data, siblingData) => {
          if (siblingData.format == 'static') {
            return true;
          } else {
            return false;
          }
        },
      },
      fields: [
        {
          type: 'row',
          fields: [
            {
              name: 'visuals',
              label: 'Visuals',
              type: 'number',
              admin: { width: '20%' },
              required: true,
            },
            {
              name: 'visualPeriod',
              label: 'Visuals Period',
              type: 'select',
              options: [
                { label: 'Daily', value: 'daily' },
                { label: 'Weekly', value: 'weekly' },
                { label: 'Monthly', value: 'monthly' },
              ],
              admin: { width: '40%' },
              required: true,
            },
            {
              name: 'visualSource',
              label: 'Statistics provided by (eg: NZTA / New World)',
              type: 'text',
              admin: { width: '40%' },
              required: true,
            },
          ],
        },
        {
          type: 'row',
          fields: [
            {
              name: 'width',
              label: 'Width (meters)',
              type: 'number',
              required: true,
              admin: {
                width: '50%',
              },
            },
            {
              name: 'height',
              label: 'Height (meters)',
              type: 'number',
              required: true,
              admin: {
                width: '50%',
              },
            },
          ],
        },
      ],
    },

    // digital meta
    {
      name: 'digitalMeta',
      label: 'Digital Options',
      type: 'group',
      admin: {
        condition: (data, siblingData) => {
          if (siblingData.format == 'digital') {
            return true;
          } else {
            return false;
          }
        },
      },
      fields: [
        {
          name: 'playVideo',
          type: 'checkbox',
          label: 'Can play video?',
        },
        {
          name: 'screens',
          type: 'array',
          label: 'Screens',
          minRows: 1,
          maxRows: 2,
          labels: {
            singular: 'Screen',
            plural: 'Screens',
          },
          fields: [
            {
              name: 'adjustable',
              type: 'checkbox',
              label: 'Adjustable?',
              defaultValue: false,
            },
            {
              type: 'row',
              fields: [
                {
                  name: 'width',
                  label: 'Width (meters)',
                  type: 'number',
                  required: true,
                  admin: {
                    width: '50%',
                  },
                },
                {
                  name: 'height',
                  label: 'Height (meters)',
                  type: 'number',
                  required: true,
                  admin: {
                    width: '50%',
                  },
                },
              ],
            },
            {
              type: 'row',
              fields: [
                {
                  name: 'pixelsWide',
                  type: 'number',
                  label: 'Pixels wide',
                  required: true,
                  admin: {
                    width: '25%',
                  },
                },
                {
                  name: 'pixelsHigh',
                  type: 'number',
                  label: 'Pixels high',
                  required: true,
                  admin: {
                    width: '25%',
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'row',
          admin: {
            condition: (data, siblingData) => {
              if (siblingData.format == 'digital') {
                return true;
              } else {
                return false;
              }
            },
          },
          fields: [
            {
              name: 'minTextHeight',
              label: 'Min. text height in pixels (0 to hide)',
              type: 'number',
              required: true,
              admin: {
                width: '50%',
              },
            },
            {
              name: 'adCycle',
              label: 'Ad cycle in seconds (>120 will display as mins)',
              type: 'number',
              required: true,
              admin: {
                width: '50%',
              },
            },
          ],
        },
        {
          type: 'row',
          fields: [
            {
              name: 'visuals',
              label: 'Visuals',
              type: 'number',
              admin: { width: '20%' },
              required: true,
            },
            {
              name: 'visualPeriod',
              label: 'Visuals Period',
              type: 'select',
              options: [
                { label: 'Daily', value: 'daily' },
                { label: 'Weekly', value: 'weekly' },
                { label: 'Monthly', value: 'monthly' },
              ],
              defaultValue: 'daily',
              admin: { width: '40%' },
              required: true,
            },
            {
              name: 'visualSource',
              label: 'Statistics provided by (eg: NZTA / New World)',
              defaultValue: 'NZTA',
              type: 'text',
              admin: { width: '40%' },
              required: true,
            },
          ],
        },
      ],
    },

    // mobile meta
    {
      name: 'mobileMeta',
      label: 'On Wheels Options',
      type: 'group',
      admin: {
        condition: (data, siblingData) => {
          if (siblingData.format == 'mobile') {
            return true;
          } else {
            return false;
          }
        },
      },
      fields: [
        {
          name: 'minTextHeight',
          label: 'Min. text height (optional)',
          type: 'number',
          admin: {
            width: '50%',
          },
        },
        {
          name: 'adjustable',
          label: 'Adjustable?',
          type: 'checkbox',
          defaultValue: true,
        },
      ],
    },

    // uploads group
    {
      name: 'uploads',
      label: 'Site Media & Documents',
      type: 'group',
      fields: [
        {
          name: 'mediaArray',
          label:
            'Media Upload - First IMAGE is thumbnail, list order used for carousel, ie. video can be first.',
          type: 'array',
          minRows: 1,
          maxRows: 10,
          fields: [
            {
              name: 'siteMedia',
              label: 'Site Media (h.264 .mp4 video @ 4:3 for best results)',
              type: 'upload',
              relationTo: 'site-media',
              required: true,
            },
          ],
        },
        {
          name: 'specSheet',
          label: 'Site specification document',
          type: 'upload',
          relationTo: 'site-documents',
        },
      ],
    },
  ],
};

export default Sites;

///// Site name - text
///// Site photograph - media upload
///// Location - select
///// On wheels - checkbox ()
///// Location description - text
///// Short description - text
///// Visuals - number (K/day)
///// Width - number (meters)
///// Height - number (meters)
///// Format - select (digital/static/mobile)
///// Min. text height
///// File format - select (jpg/mp4) -- probably just hardcode a ternary for video/static
///// Resolution (digital only)
///// Ad cycle - number (digital: N/min Static: N/month)
// Specs - file
// Site card - file
// map location....
