import { CollectionConfig } from 'payload/types';

const SiteDocuments: CollectionConfig = {
  slug: 'site-documents',
  labels: {
    singular: 'Site Document',
    plural: 'Site Documents',
  },
  admin: { group: 'Media' },
  access: {
    read: () => true,
  },
  fields: [
    {
      name: 'documentType',
      label: 'Document type',
      type: 'select',
      required: true,
      options: [
        {
          label: 'Sitecard & Production Specs ',
          value: 'sitecard',
        },
        {
          label: 'Terms & Conditions',
          value: 'terms',
        },
      ],
    },
  ],
  upload: {
    staticURL: '/site-documents',
    staticDir: 'media/site-documents',
    mimeTypes: ['application/pdf'],
  },
};

export default SiteDocuments;
