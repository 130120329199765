import { CollectionConfig } from 'payload/types';
// import slugify from 'slugify';
// import Content from '../blocks/Content';
// import { Media } from '../blocks/Media';
// import MediaContent from '../blocks/MediaContent';
// import MediaSlider from '../blocks/MediaSlider';

const Testimonials: CollectionConfig = {
  // the slug is used for naming the collection in the database and the APIs that are open. For example: api/posts/${id}
  slug: 'testimonials',
  admin: {
    // this is the name of a field which will be visible for the edit screen and is also used for relationship fields
    useAsTitle: 'client',
    group: 'Site Content',
    disableDuplicate: true,
  },
  access: {
    read: ({ req: { user } }) => {
      // users who are authenticated will see all posts
      if (user) {
        return true;
      }

      // query publishDate to control when posts are visible to guests
      return {
        and: [
          {
            publishDate: {
              less_than: new Date().toJSON(),
            },
            _status: {
              equals: 'published',
            },
          },
        ],
      };
    },
  },
  // versioning with drafts enabled tells Payload to save documents to a separate collection in the database and allow publishing
  versions: {
    drafts: true,
  },
  fields: [
    {
      name: 'client',
      type: 'text',
      label: 'Client',
    },
    {
      name: 'content',
      label: 'Testimonial',
      type: 'text',
    },
    {
      name: 'designation',
      type: 'text',
      label: 'Designation',
    },
    {
      name: 'publishDate',
      type: 'date',
      admin: {
        position: 'sidebar',
        description: 'Posts will not be public until this date',
      },
      defaultValue: () => new Date(),
    },
  ],
};

export default Testimonials;
