import { CollectionConfig } from 'payload/types';

const HeroSectionMedia: CollectionConfig = {
  slug: 'hero-section-media',
  labels: {
    singular: 'Hero Section Media',
    plural: 'Hero Section Media',
  },
  admin: { group: 'Media' },
  access: {
    read: () => true,
  },

  fields: [],
  upload: {
    staticURL: '/hero-section-media',
    staticDir: 'media/hero-section-media',
    formatOptions: {
      format: 'webp',
    },
    adminThumbnail: 'thumbnail',
    mimeTypes: ['image/*', 'video/*'],
  },
};

export default HeroSectionMedia;
