import { CollectionConfig } from 'payload/types';

const AdvertiserLogos: CollectionConfig = {
  slug: 'advertiser-logo',
  labels: {
    singular: 'Advertiser Logo',
    plural: 'Advertiser Logos',
  },
  admin: { group: 'Media' },
  access: {
    read: () => true,
  },

  fields: [],
  upload: {
    staticURL: '/advertiser-logos',
    staticDir: 'media/advertiser-logos',
    formatOptions: {
      format: 'webp',
      options: {
        lossless: true,
      },
    },

    mimeTypes: ['image/*'],
  },
};

export default AdvertiserLogos;
