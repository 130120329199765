import { GlobalConfig } from 'payload/types';

export const GeneralSettings: GlobalConfig = {
  slug: 'general',
  access: {
    read: () => true,
  },
  fields: [
    {
      name: 'termsAndConditions',
      label: 'Terms and Conditions',
      type: 'upload',
      relationTo: 'site-documents',
    },
  ],
};
