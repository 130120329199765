import { buildConfig } from 'payload/config';
import seo from '@payloadcms/plugin-seo';

import path from 'path';
import { customLogo } from './components/customLogo';

import Users from './collections/Users';
import DailyTasks from './collections/DailyTasks';
import Sites from './collections/Sites';
import Advertisers from './collections/Advertisers';
import AdvertiserCreative from './collections/AdvertiserCreative';
import SiteMedia from './collections/SiteMedia';
import SiteDocuments from './collections/SiteDocuments';
import PostMedia from './collections/PostMedia';
import Posts from './collections/Posts';
import Testimonials from './collections/Testimonials';
import AdvertiserLogos from './collections/AdvertiserLogos';
import Locations from './collections/Locations';
import HeroSection from './globals/HeroSection';
import HeroSectionMedia from './collections/HeroSectionMedia';
import { GeneralSettings } from './globals/General';

export default buildConfig({
  serverURL: process.env.PAYLOAD_PUBLIC_URL,
  cors: [process.env.FRONTEND_URL, process.env.WAI_URL].filter(Boolean),
  csrf: [
    // whitelist of domains to allow cookie auth from
    'https://wai.mad.nz',
  ].filter(Boolean),
  admin: {
    meta: {
      titleSuffix: '- MAD Media',
      favicon: '/assets/MAD.svg',
      ogImage: '/assets/MAD.svg',
    },
    // user: Users.slug,
    components: {
      graphics: {
        Logo: customLogo,
        // Icon,
      },
    },
  },
  collections: [
    Users,
    Advertisers,
    AdvertiserLogos,
    AdvertiserCreative,
    DailyTasks,
    HeroSectionMedia,
    Locations,
    Posts,
    PostMedia,
    Sites,
    SiteMedia,
    SiteDocuments,
    Testimonials,
  ],
  plugins: [
    seo({
      collections: ['posts', 'sites'],
      // uploadsCollection: 'media',
      generateTitle: ({ doc }: any) => {
        if (doc?.title) {
          return `${doc?.title?.value} - MAD Media`;
        }
        if (doc?.site) {
          return `${doc?.site?.value} - MAD Media`;
        }
      },
      generateDescription: ({ doc }: any) => {
        if (doc?.excerpt) {
          return doc?.excerpt?.value;
        }
        if (doc?.shortDescription) {
          return doc?.shortDescription.value;
        }
      },
    }),
  ],
  globals: [HeroSection, GeneralSettings],

  typescript: {
    outputFile: path.resolve(__dirname, 'payload-types.ts'),
  },
  graphQL: {
    schemaOutputFile: path.resolve(__dirname, 'generated-schema.graphql'),
  },
});
