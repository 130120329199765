import { CollectionConfig } from 'payload/types';

const SiteMedia: CollectionConfig = {
  slug: 'site-media',
  labels: {
    singular: 'Site Media',
    plural: 'Site Media',
  },
  admin: { group: 'Media' },
  access: {
    read: () => true,
  },

  fields: [
    {
      name: 'alt',
      label: 'Alt text - (describe the image to a blind person)',
      type: 'text',
      required: true,
    },
  ],
  upload: {
    staticURL: '/site-media',
    staticDir: 'media/site-media',
    formatOptions: {
      format: 'webp',
    },
    imageSizes: [
      {
        name: 'full',
        width: 1060,
        height: 795,
        position: 'centre',
        formatOptions: {
          format: 'webp',
        },
      },
    ],
    adminThumbnail: 'thumbnail',
    mimeTypes: ['image/*', 'video/*'],
  },
};

export default SiteMedia;
