import { CollectionConfig } from 'payload/types';
import { admins } from '../access/admins';
import { adminsAndUser } from '../access/adminsAndUser';

const DailyTasks: CollectionConfig = {
  slug: 'daily-tasks',
  admin: {
    useAsTitle: 'staff',
  },
  
  access: {
    create: () => true,
    read: adminsAndUser,
    update: adminsAndUser,
    delete: admins,
  },
  fields: [
    {
      name: 'staff',
      label: 'Staff',
      type: 'relationship',
      relationTo: 'users',
    },
    {
      name: 'date',
      type: 'date', // required
      label: 'Day',
      admin: {
        date: {
          pickerAppearance: 'dayOnly',
        },
      },
    },
    {
      name: 'tasks', // required
      type: 'array', // required
      label: 'Daily Tasks',
      maxRows: 20,
      admin: {},
      labels: {
        singular: 'Task',
        plural: 'Tasks',
      },
      fields: [
        {
          name: 'taskName',
          type: 'text',
        },
        {
          name: 'timing',
          type: 'select',
          options: [
            { label: 'AM', value: 'am' },
            { label: 'PM', value: 'pm' },
          ],
        },
        {
          name: 'complete',
          type: 'checkbox',
        },
        {
          name: 'location',
          type: 'select',
          options: [
            { label: 'Office', value: 'office' },
            { label: 'Home', value: 'home' },
            { label: 'Field', value: 'field' },
          ],
        },
      ],
    },
  ],
};

export default DailyTasks;
