import { CollectionConfig } from 'payload/types';
import slugify from 'slugify';
import CustomHighlight from '../components/RichText/Leaves/CustomHighlight';

const Posts: CollectionConfig = {
  slug: 'posts',
  admin: {
    useAsTitle: 'title',
    defaultColumns: ['title', 'category', 'publishDate', 'tags', 'status'],
    group: 'Site Content',
    disableDuplicate: true,
  },
  access: {
    read: ({ req: { user } }) => {
      // users who are authenticated will see all posts
      if (user) {
        return true;
      }

      // query publishDate to control when posts are visible to guests
      return {
        and: [
          {
            publishDate: {
              less_than: new Date().toJSON(),
            },
            _status: {
              equals: 'published',
            },
          },
        ],
      };
    },
  },
  versions: {
    drafts: true,
  },
  fields: [
    {
      name: 'slug',
      type: 'text',
      unique: true,
      index: true,
      admin: {
        readOnly: true,
      },
      hooks: {
        beforeValidate: [
          ({ req: { payload }, data }) => {
            if (payload) {
              if (data._status === 'draft') {
                return;
              }
              return slugify(data.title, { lower: true });
            }
          },
        ],
      },
    },

    {
      name: 'title',
      type: 'text',
    },
    {
      name: 'excerpt',
      label: 'Excerpt',
      type: 'text',
    },
    {
      name: 'featuredImage',
      label: 'Feature Image - minimum image size: 1200x600',
      type: 'upload',
      relationTo: 'post-media',
    },
    {
      name: 'content',
      type: 'richText',
      admin: {
        elements: [
          'h2',
          'h3',
          'h4',
          'link',
          'ol',
          'ul',
          'indent',
          'blockquote',
        ],
        leaves: [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          CustomHighlight,
        ],
      },
    },
    {
      name: 'author',
      type: 'relationship',
      relationTo: 'users',
      // defaultValues can use functions to return data to populate the create form and also when making POST requests the server will use the value or function to fill in any undefined fields before validation occurs
      defaultValue: ({ user }) => user.id,
      admin: {
        position: 'sidebar',
      },
    },
    {
      name: 'publishDate',
      type: 'date',
      admin: {
        position: 'sidebar',
        description: 'Posts will not be public until this date',
      },
      defaultValue: () => new Date(),
    },
  ],
};

export default Posts;
