import { GlobalConfig } from 'payload/types';
import CustomHighlight from '../components/RichText/Leaves/CustomHighlight';

const HeroSection: GlobalConfig = {
  slug: 'hero-section',
  access: {
    read: () => true,
  },
  fields: [
    // richTextHeroField({ name: 'mainContent' }),
    {
      name: 'enableHeadline',
      label: 'Enable Headline Copy',
      type: 'checkbox',
    },
    {
      name: 'headline',
      label:
        'Headline (leave blank to show background media only without gradient overlay)',
      type: 'richText',
      admin: {
        elements: ['h1'],
        leaves: [CustomHighlight],
        condition: (data, siblingData) => {
          if (siblingData.enableHeadline == true) {
            return true;
          } else {
            return false;
          }
        },
      },
    },
    {
      name: 'enableCtaButton',
      label: 'Enable CTA Button',
      type: 'checkbox',
      admin: {
        condition: (data, siblingData) => {
          if (siblingData.enableHeadline == true) {
            return true;
          } else {
            return false;
          }
        },
      },
    },
    {
      type: 'row',
      admin: {
        condition: (data, siblingData) => {
          if (
            siblingData.enableHeadline == true &&
            siblingData.enableCtaButton == true
          ) {
            return true;
          } else {
            return false;
          }
        },
      },
      fields: [
        {
          name: 'buttonText',
          type: 'text',
          required: true,
        },
        {
          name: 'ctaLink',
          type: 'text',
          label: 'Full URL (including https://)',
          required: true,
        },
        {
          type: 'row',
          admin: {
            condition: (data, siblingData) => {
              if (
                siblingData.enableHeadline == true &&
                siblingData.enableCtaButton == true
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
          fields: [
            {
              name: 'externalLink',
              type: 'checkbox',
              label: 'Open in new tab?',
            },
          ],
        },
      ],
    },

    {
      name: 'media',
      type: 'group',
      fields: [
        {
          name: 'mediaType',
          label: 'Background Media Type',
          type: 'radio',
          options: [
            { label: 'Video', value: 'video' },
            { label: 'Image', value: 'image' },
          ],
        },
        {
          name: 'videoContent',
          type: 'group',
          fields: [
            {
              name: 'video',
              label: 'Video Upload (1920x1080 MP4 H.264 - 20mb max)',
              type: 'upload',
              relationTo: 'hero-section-media',
              required: true,
            },
            {
              name: 'firstFrame',
              label: 'First Frame Upload (1920x1080 JPG)',
              type: 'upload',
              relationTo: 'hero-section-media',
              required: true,
            },
          ],
          admin: {
            condition: (data, siblingData) => {
              if (siblingData.mediaType == 'video') {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        {
          name: 'imageContent',
          type: 'group',
          fields: [
            {
              name: 'image',
              label: 'Hero Image Upload (1920x1080 JPG)',
              type: 'upload',
              relationTo: 'hero-section-media',
              required: true,
            },
          ],
          admin: {
            condition: (data, siblingData) => {
              if (siblingData.mediaType == 'image') {
                return true;
              } else {
                return false;
              }
            },
          },
        },
      ],
    },
  ],
};

export default HeroSection;
