import { CollectionConfig } from 'payload/types';

const Locations: CollectionConfig = {
  slug: 'locations',

  admin: {
    useAsTitle: 'location',
    defaultColumns: ['location', 'id'],
    group: 'Site Content',
    disableDuplicate: true,
  },
  access: {
    read: () => true,
  },
  fields: [
    {
      name: 'location',
      type: 'text',
    },
  ],
};

export default Locations;
