import { CollectionConfig } from 'payload/types';
import slugify from 'slugify';

const Advertisers: CollectionConfig = {
  slug: 'advertisers',
  labels: {
    singular: 'Advertiser',
    plural: 'Advertisers',
  },
  admin: {
    useAsTitle: 'advertiser',
    group: 'Site Content',
    disableDuplicate: true,
  },
  access: {
    read: () => true,
  },
  // versioning with drafts enabled tells Payload to save documents to a separate collection in the database and allow publishing
  // versions: {
  //   drafts: true,
  // },
  fields: [
    {
      name: 'slug',
      label: 'Advertiser Slug',
      type: 'text',
      admin: {
        position: 'sidebar',
      },
      hooks: {
        beforeValidate: [
          ({ req: { payload }, data }) => {
            if (payload) {
              return slugify(data.advertiser, { lower: true });
            }
          },
        ],
      },
    },
    {
      type: 'tabs',
      tabs: [
        {
          label: 'Advertiser Details',
          fields: [
            {
              name: 'advertiser',
              label: 'Advertiser name',
              type: 'text',
            },
            {
              name: 'website',
              label: 'URL',
              type: 'text',
            },
            {
              name: 'phone',
              label: 'Phone',
              type: 'text',
            },
            {
              name: 'communityPartner',
              label: 'Community partner',
              type: 'checkbox',
              defaultValue: false,
            },
            {
              name: 'logo',
              label: 'Logo',
              type: 'upload',
              relationTo: 'advertiser-logo',
              required: true,
            },
          ],
        },
        {
          label: 'Creative',
          fields: [
            {
              name: 'creative',
              type: 'array',
              label: 'Drag & drop - first will be featured',
              required: true,
              minRows: 1,
              maxRows: 10,
              fields: [
                {
                  name: 'site',
                  required: true,
                  type: 'relationship',
                  relationTo: 'sites',
                  admin: {
                    allowCreate: false,
                  },
                  // limit the options using the below query which uses the "archive" field set in the categories collection
                  // filterOptions: {
                  //   archived: { equals: false },
                  // },
                  // allow selection of one or more categories
                  hasMany: false,
                  // required:
                },
                {
                  name: 'image',
                  type: 'upload',
                  relationTo: 'advertiser-creative',
                  required: true,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default Advertisers;

/// location
/// site
/// creative - array of images
/// feature creative - first image is the feature iamge
/// slug

//////// advertiser details
/// logo
/// name
/// website
/// phone

// each creative array element needs to have a "site" which is related to location
// additionally, make the locations list dynamic, and link to sites
